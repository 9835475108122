import React from 'react';
// import axios from 'axios';
// import data from '@emoji-mart/data'
// import Picker from '@emoji-mart/react'
import EmojiPicker from 'emoji-picker-react';

import '../../assets/css/bootstrap.min.css';
import '../../assets/css/framework.new.css';
import '../../assets/css/responsive.css';
import '../../assets/css/style.css';

const Input = ({setMessage, sendMessage, message, type, room, socket}) => {
    // const [emojiDisplay, setEmojiDisplay] = React.useState(false);
    const emojiDisplay = false;

    // const handleFile = (event) => {
        // const cdnUrl = 'https://discord.com/api/webhooks/1223999559781322814/aplHDZNeOPHR6N5nXgRfffVFu3UBp4a2b-Oj9BTR-qnG6rQKHuLBHhXPiaAhJAdW_60w';

        // const fileInput = event.target;
        // const file = fileInput.files[0];

        // const formData = new FormData();
        // formData.append('file', file, 'image.png');

        // axios.post(cdnUrl, formData, {
        //     headers: {
        //         'Content-Type': 'multipart/form-data'
        //     }
        // })
        // .then(response => {
        //     const imageUrl = response.data.attachments[0].url;

        //     socket.emit('sendClientImage', imageUrl, (error) => {
        //         if (error) {
        //             alert(error);
        //         }
        //     });
        // })
        // .catch(error => {
        //     console.error('Webhook gönderilirken hata oluştu:', error);
        // });

        // fileInput.value = null;
    // }
    const handleFile = (event) => {
        const fileInput = event.target;
        const file = fileInput.files[0];

        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                const base64Data = reader.result.split(',')[1]; // Base64 verisini alın
                const mimeType = file.type;
                const filename = file.name;
                const fileSize = file.size;

                let type;
                if (mimeType.startsWith('image/')) {
                    type = 'image';
                } else if (mimeType.startsWith('video/')) {
                    type = 'video';
                } else {
                    type = 'other';
                }

                // Verileri socket.emit ile gönder
                socket.emit('sendClientMedia', filename, base64Data, mimeType, fileSize, type, (error) => {
                    if (error) {
                        alert(error);
                    }
                });
            };
            reader.readAsDataURL(file);
        }
    
        fileInput.value = null;
    }

    const handleKeyUp = (event) => {
        // Sadece harf veya rakam tuşlarına izin vermek için bir kontrol yapalım
        const isAlphaNumericKey = /^[a-zA-Z0-9]$/; // Harf veya rakam olup olmadığını kontrol eden bir regex
    
        const inputValue = event.target.value;
    
        // Tuş yukarıda kaldığında işlem yap
        if (isAlphaNumericKey.test(event.key) || event.key === 'Backspace') {
            // Eğer basılan tuş harf veya rakamsa ya da Backspace tuşuysa
            const updatedMessage = inputValue; // Değişiklik yok, sadece mevcut değeri al
            if (!isOnlyWhitespace(updatedMessage)) {
                // Yeni mesajı göndermeden önce kontrol et
                socket.emit('typing', { room, message: updatedMessage }, (error) => {
                    if (error) {
                        alert(error);
                    }
                });
            } else {
                socket.emit('typing', { room, message: false }, (error) => {
                    if (error) {
                        alert(error);
                    }
                });
            }
        }

        if (event.key === 'Enter') {
            // Enter tuşuna basıldığında, message: false gönder
            socket.emit('typing', { room, message: false }, (error) => {
                if (error) {
                    alert(error);
                }
            });
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            sendMessage(event);
        }
    }
    

    if (type === "2") {
        return (
            <div className="p-3">
                <div className="write-message mt-5">
                    <form action="">
                        <div className="d-block align-items-center">
                            <div className="w-100 text-center">
                                <div className="alert alert-warning">
                                    Destek talebi sonlandırıldı.
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        );
    } else if (type === "3") {
        return (
            <div className="p-3">
                <div className="write-message mt-5">
                    <form action="">
                        <div className="d-block align-items-center">
                            <div className="w-100 text-center">
                                <div className="alert alert-danger">
                                    Destek talebinden yasaklanmışsınız!
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        );
    } else if (type === "4") {
        return (
            <div className="p-3">
                <div className="write-message mt-5">
                    <form action="">
                        <div className="d-block align-items-center">
                            <div className="w-100 text-center">
                                <div className="alert alert-danger">
                                    Bağlanılıyor...
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        );
    } else {
        return (
            // <><div className="emoji-picker-container" style={{ display: emojiDisplay === true ? 'block' : 'none', position: 'absolute', zIndex: '9999' }}>
            //     <EmojiPicker emojiStyle='native' skinTonesDisabled={true} lazyLoadEmojis={true} onEmojiClick={(emoji) => {
            //         setMessage(message + emoji.emoji);
            //     } } />
            // </div>
            <div className="p-3">
                    <div className="write-message mt-5">
                        <form action="">
                            <div className="d-block align-items-center">
                                <div className="w-100 text-center">
                                    <p className="mb-0 font-weight-bold small">Chat by Gameixa</p>
                                    <div className="position-relative overflow-hidden">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Mesaj Yaz"
                                            value={message}
                                            onChange={({ target: { value } }) => setMessage(value)}
                                            onKeyUp={handleKeyUp}
                                            onKeyPress={handleKeyPress} />
                                        <button type="button" className="submit-div" onClick={sendMessage} onKeyPress={handleKeyPress}>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M14.4301 5.92993L20.5001 11.9999L14.4301 18.0699" stroke="#fff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                <path d="M3.5 12H20.33" stroke="#fff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </button>
                                        {/* <div className="alt-bar" style={{ textAlign: 'left', marginTop: '5px' }}>
                                            <label htmlFor="file-input">
                                                <img src="https://i.hizliresim.com/jwegh4q.png" alt="attach" style={{ width: '30px', height: '30px', cursor: 'pointer' }} />
                                            </label>
                                            <input id="file-input" type="file" accept=".jpg, .jpeg, .png" onChange={handleFile} style={{ display: 'none' }}/>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                // </>
        );
    }
}

function isOnlyWhitespace(inputString) {
    // Boşluktan oluşup oluşmadığını kontrol eden regex
    const whitespaceRegex = /^\s*$/;
  
    // Test metodu ile regex uygula
    return whitespaceRegex.test(inputString);
}

export default Input;
