import React, { useState, useEffect } from 'react';
import '../../assets/css/bootstrap.min.css';
import '../../assets/css/framework.new.css';
import '../../assets/css/responsive.css';
import '../../assets/css/style.css';
import axios from 'axios';

const InfoBar = ({ room, isLiveChatVisible, setLiveChatVisible, LiveChatVisible, changeLiveChatVisible, domain, apiKey }) => {
    const [logo, setLogo] = useState('');
    const [name, setName] = useState('');

    const parseDomain = (url) => {
        if (!url) url = 'http://gameixa.com'

        url = url.toLowerCase();
        let hostname;
    
        if (url.startsWith('http://') || url.startsWith('https://')) {
            // URL'i direkt olarak kullan
            hostname = url.split('/')[2];
        } else {
            // URL'i // ile başlatılmışsa ekleyerek kullan
            hostname = `//${url}`.split('/')[2];
        }
    
        if (hostname) {
            if (hostname.startsWith('www.')) {
                return hostname.slice(4);
            }
            return hostname;
        }
    
        return false;
    }

    useEffect(() => {
        const fetchData = async () => {
            let storedData = localStorage.getItem('logoAndName');
            if (storedData) {
                storedData = JSON.parse(storedData);

                storedData.logo = 'https://i.hizliresim.com/i31um5b.png';

                setLogo(storedData.logo);
                setName(storedData.name);
            } else {
                try {
                    const res = await axios.post(
                        'https://api.gameixa.com/v3/services/livechat.check.php',
                        {
                            productID: 29,
                            domain: parseDomain(domain),
                            apiKey: apiKey,
                        },
                        {
                            headers: { 'Content-Type': 'multipart/form-data' },
                        }
                    )

                    const data = res.data;
                    setLogo(data.company_logo);
                    setName(data.company_name);
                    
                    // Yalnızca verileri API'den aldıktan sonra localStorage'e kaydet
                    localStorage.setItem('logoAndName', JSON.stringify({ logo: data.company_logo, name: data.company_name }));
                } catch (error) {
                    console.error('API verileri getirilemedi', error);
                }
            }
        };

        fetchData();
    }, [apiKey, domain]);


    const toggleLiveChat = () => {
        setLiveChatVisible(!isLiveChatVisible);
        changeLiveChatVisible(!LiveChatVisible);
    };

    return (
        <div className="card-header d-flex">
            <div className="w-100 d-flex">
                <div className="d-flex align-items-center w-75">
                    <img className="live-chat-logo rounded-full" src={logo} alt="" />
                    <p className="h6 mb-0 ms-3 live-chat-company-name">{name}</p>
                </div>
            </div>
            <div className="w-15 text-end align-items-center d-flex justify-content-end">
                <button className="mt-1 hover-pointer" style={{ background: 'none', border: 'none' }} onClick={toggleLiveChat}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none">
                        <path d="M13.9902 10.0099L14.8302 9.16992" stroke="#fff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                        <path d="M9.16992 14.8301L11.9199 12.0801" stroke="#fff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                        <path d="M14.8299 14.8299L9.16992 9.16992" stroke="#fff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                        <path d="M4 6C2.75 7.67 2 9.75 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2C10.57 2 9.2 2.3 7.97 2.85" stroke="#fff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                    </svg>
                </button>
            </div>
        </div>
    );
};

export default InfoBar;