import React, {useState, useEffect} from "react"; // useRef
import { FaCommentAlt, FaTimes } from 'react-icons/fa';
// import {Helmet} from "react-helmet";
import io from "socket.io-client";
import NotificationSound from './notify.mp3'
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

// import TextContainer from '../TextContainer/TextContainer';
import Messages from '../Messages/Messages';
import InfoBar from '../InfoBar/InfoBar';
import Input from '../Input/Input';

// import EmojiPicker from 'emoji-picker-react';

//import '../../assets/css/all.min.css';
import '../../assets/css/bootstrap.min.css';
//import '../../assets/css/fontawesome.min.css';
import '../../assets/css/framework.new.css';
import '../../assets/css/responsive.css';
import '../../assets/css/style.css';

const ENDPOINT = 'https://chatserver.gameixa.com:2083';//https://chatserver.gameixa.com:2083

let socket;
// let isTabActive = true;
let LiveChatVisible = false;
let realApikey;
let realDomain;

const Chat = () => {
    const location = useLocation();

    const [name, setName] = useState('');
    const [room, setRoom] = useState('');
    // const [users, setUsers] = useState('');
    const [message, setMessage] = useState('');
    const [messages, setMessages] = useState([]);
    const [isLiveChatVisible, setLiveChatVisible] = useState(false);
    // const [isTabActive, setIsTabActive] = useState(true);
    const [isInvalidApiKey, setIsInvalidApiKey] = useState(false);

    //const [isSmallSize, setIsSmallSize] = useState(true);

    // useEffect(() => {
    //     document.body.addEventListener("click", handleClick);

    //     return () => {
    //         document.body.removeEventListener("click", handleClick);
    //     };
    // }, [isLiveChatVisible, isSmallSize]);

    // const handleClick = () => {
    //     if (isLiveChatVisible) {
    //         const newSize = isSmallSize ? { width: "450px", height: "700px" } : { width: "90px", height: "70px" };
    //         window.parent.postMessage({ action: "resizeIframe", ...newSize }, "*");
    //         //setIsSmallSize(!isSmallSize);
    //     }
    // };

    useEffect(() => {
        const { apiKey } = queryString.parse(location.search);
        realApikey = apiKey;
        const name = "Visitor";
        const room = window.localStorage.getItem('room');
        const domain = window.location.ancestorOrigins[0];
        realDomain = domain;

        socket = io(ENDPOINT, {
            reconnection: true,
            reconnectionAttempts: Infinity,
            reconnectionDelay: 1000,
            reconnectionDelayMax: 5000,
            randomizationFactor: 0.5
        });

        setRoom(room);
        setName(name);

        socket.emit('join', {name, room, apiKey, domain}, (error) => {
            if (error) {
                alert(error);
            }
        });

    }, [location.search]);

    useEffect(() => {
        socket.on('message', message => {
            setMessages(messages => [...messages, message]);
        });

        // socket.on("roomData", ({users}) => {
        //     setUsers(users);
        // });

        socket.on('changeChatType', async ({newChatType}) => {
            window.localStorage.setItem('chatType', newChatType);
            setMessage(createRandomString(15));
            setMessage('');
        });

        socket.on('changeRoom', ({newRoom}) => {
            window.localStorage.setItem('room', newRoom);
            window.location.reload();
        });

        socket.on('refreshPage', () => {
            window.location.reload();
        });

        socket.on('playNotificationSound', () => {
            const liveChatElement = document.querySelector('.live-chat') ? document.querySelector('.live-chat') : false;
            const localIsLiveChatVisible = liveChatElement ? liveChatElement.classList.contains('show') : false;
            if (!localIsLiveChatVisible) {
                const audio = new Audio(NotificationSound);
                audio.volume = 0.1;
                audio.play()
            }
        });

        socket.on('invalidapikey', () => {
            setIsInvalidApiKey(true);
        });
    }, []);

    const sendMessage = (event) => {
        event.preventDefault();

        if (message) {
            socket.emit('sendMessage', message, () => setMessage(''));
        }
    }

    const toggleLiveChat = () => {
        setLiveChatVisible(!isLiveChatVisible);
        LiveChatVisible = !LiveChatVisible;

        const liveChatElement = document.querySelector('.live-chat') ? document.querySelector('.live-chat') : false;
        const localIsLiveChatVisible = liveChatElement ? liveChatElement.classList.contains('show') : false;

        if (localIsLiveChatVisible) {
            const newSize = { width: "90px", height: "70px" };
            window.parent.postMessage({ action: "resizeIframe", ...newSize }, "*");
        } else {
            const newSize = { width: "450px", height: "700px" };
            window.parent.postMessage({ action: "resizeIframe", ...newSize }, "*");
        }
    };

    const changeLiveChatVisible = (value) => {
        LiveChatVisible = value;
    }

    // return (
    //     <main>
    //         {checkVaildApiKey(realApikey) ? (
                
    //         ) : (
    //             <div class="m-auto d-flex justify-content-center">
    //                 <div class="w-100 text-center w-75 btn btn-api">
    //                     <img src="https://resmim.net/cdn/2024/01/24/ZO1e8G.png" alt=""/>
    //                     API NOT FOUND!!
    //                     <br/>
    //                     <a href="https://discord.gameixa.com" class="api-link" target="_blank">Contact Us</a>
    //                 </div>
    //             </div>
    //         )}
    //     </main>
    // );

    if (isInvalidApiKey) {
        return (
            <div className="m-auto d-flex justify-content-center">
              <div className="w-100 text-center w-75 btn btn-api">
                <img src="https://resmim.net/cdn/2024/01/24/ZO1e8G.png" alt=""/>
                API NOT FOUND!!
                <br/>
                <a href="https://discord.gameixa.com" className="api-link" target="_blank" rel="noopener noreferrer">Contact Us</a>
              </div>
            </div>
        )
    }

    return (
        <main>
            <section className="livechat-section">
                <div className="fixed-button chat-button" onClick={toggleLiveChat}>
                    <button className="d-flex" style={{ background: 'none', border: 'none' }}>
                        {isLiveChatVisible ? <FaTimes className="icon-white" /> : <FaCommentAlt className="icon-white" />}
                    </button>
                </div>
                {LiveChatVisible ? (
                    <div className={`live-chat ${LiveChatVisible ? "show" : ""}`}>
                        <div className="card">
                            <InfoBar room={room} isLiveChatVisible={isLiveChatVisible} setLiveChatVisible={toggleLiveChat} LiveChatVisible={LiveChatVisible} changeLiveChatVisible={changeLiveChatVisible} apiKey={realApikey} domain={realDomain}/>
                            <Messages messages={messages} name={name}/>
                            <Input type={window.localStorage.getItem('chatType')} message={message} setMessage={setMessage} sendMessage={sendMessage} room={room} socket={socket}/>
                        </div>
                    </div>
                ) : null}
            </section>
        </main>
    )
}


function createRandomString(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;

    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
}

export default Chat;