import React from 'react';

import '../../../assets/css/bootstrap.min.css';
import '../../../assets/css/framework.new.css';
import '../../../assets/css/responsive.css';
import '../../../assets/css/style.css';

import ReactEmoji from 'react-emoji';

const renderText = (text) => {
    if (typeof text !== 'string') return null;

    const tenorRegex = /https:\/\/tenor\.com\/(?:tr\/)?view\/([^/?]+)(?:\?[^/]+)?/;
    const matchTenor = text.match(tenorRegex);
    if (matchTenor) return <p className="mb-0"></p>;

    const regex = /(https?:\/\/cdn\.discordapp\.com\/emojis\/(\d+)\.(png|gif))/g;
    let match;
    let lastIndex = 0;
    const renderedParts = [];

    // Emojileri bulma ve metni parçalama
    while ((match = regex.exec(text)) !== null) {
        // Emoji öncesi metin parçasını al
        const preEmojiText = text.substring(lastIndex, match.index);
        // Emojiyi oluştur ve parçalar dizisine ekle
        if (preEmojiText) {
            renderedParts.push(ReactEmoji.emojify(preEmojiText));
        }
        // Emojiyi oluştur ve parçalar dizisine ekle
        renderedParts.push(
            <img key={match[2]} src={match[0]} alt="Emoji" style={{ width: '24px', height: '24px' }} />
        );
        // Son indeksi güncelle
        lastIndex = match.index + match[0].length;
    }

    // Urlleri herf yap
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const urlMatches = text.match(urlRegex);
    if (urlMatches) {
        urlMatches.forEach((url) => {
            if (!url.startsWith('https://cdn.discordapp.com/emojis/')) {
                const preUrlText = text.substring(lastIndex, text.indexOf(url));
                if (preUrlText) {
                    renderedParts.push(ReactEmoji.emojify(preUrlText));
                }

                renderedParts.push(
                    <a key={url} href={
                        url.startsWith('http://') || url.startsWith('https://') ? url : `http://${url}`
                    } target="_blank" rel="noopener noreferrer">
                        {url}
                    </a>
                );
                lastIndex = text.indexOf(url) + url.length;   
            }
        });
    }

    // Son kalan metin parçasını ekle
    const remainingText = text.substring(lastIndex);
    if (remainingText) {
        renderedParts.push(ReactEmoji.emojify(remainingText));
    }

    // Parçaları döndür
    return <p className="mb-0">{renderedParts}</p>;
};




const gifIframe = (url) => {
    const tenorRegex = /https:\/\/tenor\.com\/(?:tr\/)?view\/([^/?]+)(?:\?[^/]+)?/;
    const matchTenor = url.match(tenorRegex);

    if (matchTenor) {
        // const tenorId = matchTenor[1];
        return (
            <div style={{margin: '0 auto'}}>
                <img style={{width: '100%'}} src={url + ".gif"} alt="Tenor GIF"/>
            </div>
        );
    }

    return null;
};

const youtubeIframe = (url) => {
    const youtubeRegex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=|shorts\/)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const match = url.match(youtubeRegex);

    if (match) {
        const videoId = match[1];
        return (
            <div style={{margin: '0 auto'}}>
                <iframe width="100%" height="315" src={`//www.youtube.com/embed/${videoId}`} frameBorder="0" allowFullScreen title="YouTube Video"/>
            </div>
        );
    }

    return null;
};

const Message = ({message: {text, user, image, video, file, voice}, name}) => {
    let isSentByCurrentUser = false;

    const trimmedName = name.trim().toLowerCase();

    if (user === trimmedName) {
        isSentByCurrentUser = true;
    }

    return (
        <div className="card-body p-0">
            <div className={isSentByCurrentUser ? 'p-4 pb-0 pt-0' : 'p-4 pt-0 pb-0'}>
                <p className={isSentByCurrentUser ? 'author-message text-gray-company mb-0' : 'company-name text-gray-company mb-0 text-start'}>
                    {user}
                </p>
                <div className={isSentByCurrentUser ? 'author-message col-xl-12 mb-3' : 'company-message col-xl-12 mb-3'}>
                    <div className={(image || video || file) ? 'message-image-box' : voice ? '' : 'message-box'}>
                        {renderText(text)}
                        {youtubeIframe(text)}
                        {gifIframe(text)}
                        {image && <img className="w-100" src={image} alt="attachment preview"/>}
                        {video && (
                            <video className="w-100" controls>
                                <source src={video} type="video/mp4"/>
                            </video>
                        )}
                        {file && (
                            <a href={file.split('<AYIRAC>')[0]} className="button button-download" target="_blank" rel="noopener noreferrer">
                                <ul>
                                    <li>{file.split('<AYIRAC>')[1]}</li>
                                </ul>
                                <div>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M8.47021 10.74L12.0002 14.26L15.5302 10.74" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </div>
                            </a>
                        )}
                        {voice && (
                            <audio id="voiceMessage" controls>
                                <source src={voice} type="audio/ogg"/>
                            </audio>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Message;