import React, {useEffect} from 'react';
import ScrollToBottom from 'react-scroll-to-bottom';
import Message from './Message/Message';
import '../../assets/css/bootstrap.min.css';
import '../../assets/css/framework.new.css';
import '../../assets/css/responsive.css';
import '../../assets/css/style.css';

const Messages = ({messages, name, image, video, file, voice}) => {
    useEffect(() => {
        const messagesDiv = document.querySelector('.messages');
        if (messagesDiv) {
            messagesDiv.scrollTop = messagesDiv.scrollHeight;
        }
    }, [messages]);  // This effect will re-run whenever messages change

    return (
        <ScrollToBottom className="messages">
            {messages.map((message, i) => (
                <div key={i}>
                    <Message message={message} name={name} image={image} video={video} file={file} voice={voice}/>
                </div>
            ))}
        </ScrollToBottom>
    );
};

export default Messages;
