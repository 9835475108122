import React from 'react';

import Chat from './components/Chat/Chat';
//import Join from './components/Join/Join';
//<Route path="/" exact component={Join} />

import {BrowserRouter as Router, Route} from "react-router-dom";

const App = () => {

    var roomID = window.localStorage.getItem('room');
    var chatType = window.localStorage.getItem('chatType');

    if (roomID === null) {
        localStorage.setItem('room', randomRoom(128));
    }

    if (chatType === null) {
        localStorage.setItem('chatType', '1');
    }

    //chat/:publicApiKey
    return (
        <Router>
            <Route path="/" exact component={Chat}/>
        </Router>
    );
}

function randomRoom(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export default App;